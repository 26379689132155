
.details-index {
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  line-height: 60px;
  > div {
    color: #333;
    > span {
      color: #999;
    }
  }
}
